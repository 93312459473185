const plans = [
  // {
  //   "name": "Feedback",
  //   "features": [
  //     "1 User",
  //     "Unlimited Feedback",
  //     "Unlimited Customers",
  //     "GDPR Compliant"
  //   ],
  //   "price-Month": 29,
  //   "price-Year": 25,
  //   "diff": 5,
  //   "descriptions": "For solopreneurs, side hustlers and people who are just starting out",
  //   "additionalUsers": "Additional Users: Not Available",
  //   "ispopular": false
  // },
  {
    "name": "Idea",
    "min": 3,
    "features": [
      "Unlimited Feedback",
      "Unlimited Testimonial",
      "Unlimited Ideas & Themes",
      "Unlimited Customers",
      "Unlimited Accounts",
      "API Access"
    ],
    "price-Month": 49,
    "price-Year": 39,
    "diff": 10,
    "descriptions": "For Entrepreneurs, Small Business & Startups",
    // "additionalUsers": "Additional Users: $19/user/month",
    "ispopular": false
  }];

export default plans;
