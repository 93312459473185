import React from "react";

import Layout from "../layouts";
import SEO from "../components/seo";
import PricingTable from "../sections/pricingtable";
import FAQSVG from "../images/faq.svg";
import keywords from "../data/keywords.json";

import faq from "../data/faq.json";

function PricingPage() {
  return (
    <Layout>
      <SEO keywords={keywords} title="Pricing" />
      <section className="w-full p-2 bg-mph-light-gray">
        <div className="w-full flex flex-col items-center justify-center p-5 space-y-4">
          <h2 className="font-bold text-2xl sm:text-5xl text-center text-black tracking-wide leading-loose">
            Pricing
          </h2>
          <span className="text-base sm:text-xl text-gray-400 text-center tracking-wider">
            Try for 14 days before buying. <br />
            No Credit Card Required. No Commitments.
          </span>
        </div>
      </section>
      <PricingTable />
      <section className="w-full p-4 flex flex-col items-center justify-center">
        <img src={FAQSVG} alt="Frequently Asked Questions" />
        <p className="font-bold p-4 text-xl sm:text-4xl">
          Frequently Asked Questions
        </p>
        <div className="flex flex-col space-y-5 text-md sm:text-xl text-gray-600 w-full sm:w-1/2">
          {faq.map(({ summary, details }) => {
            return (
              <>
                <details className="p-2">
                  <summary className="p-2 text-black text-xl font-bold">
                    {summary}
                  </summary>
                  <p className="text-gray-500">{details}</p>
                </details>
              </>
            );
          })}
        </div>
      </section>
    </Layout>
  );
}

export default PricingPage;
