import React, { useState } from "react";
import { Link } from "gatsby";

import pricingData from "../data/pricing.js";

function PricingTable() {
  const [cycle, setCycle] = useState("Year");

  return (
    <>
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-10 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <div className="flex mx-auto border-2 border-mph-ocean-blue rounded overflow-hidden mt-6">
              <button
                onClick={() => setCycle("Month")}
                className={`py-1 px-4 focus:outline-none ${
                  cycle === "Month" ? "bg-mph-ocean-blue text-white" : ""
                }`}
              >
                Monthly
              </button>
              <button
                onClick={() => setCycle("Year")}
                className={`py-1 px-4 focus:outline-none ${
                  cycle === "Year" ? "bg-mph-ocean-blue text-white" : ""
                }`}
              >
                Annually
              </button>
            </div>
          </div>
          <div className="flex flex-wrap -m-4 justify-center">
            {pricingData.map((item) => {
              return (
                <>
                  <div className="p-4 xl:w-1/4 md:w-1/2 w-full">
                    <div
                      className={`h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden ${
                        item.ispopular === true ? "border-mph-orange" : ""
                      }`}
                    >
                      {item.ispopular === true && (
                        <span className="bg-mph-orange text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                          POPULAR
                        </span>
                      )}

                      <h1 className="text-2xl text-mph-ocean-blue pb-2 mb-2 leading-none text-center">
                        {item.name}
                      </h1>
                      <h2 className="text-3xl tracking-widest font-bold mb-4 pb-2 border-b border-gray-200 text-center">
                        ${item[`price-${cycle}`]} <br />
                        <span className="text-sm">
                          per user/month <br />
                          {cycle === "Year"
                            ? `(save $${item.diff * 12} yearly)`
                            : ""}
                        </span>
                        <p className="text-xs text-gray-500 mt-3">
                          (min: {item.min} paid users)
                        </p>
                        <p className="text-xs text-gray-500 mt-3">
                          {item.descriptions}
                        </p>
                      </h2>
                      {item.features.map((ft) => {
                        return (
                          <>
                            <p className="flex items-center text-gray-600 mb-4">
                              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-mph-gray-blue text-black rounded-full flex-shrink-0">
                                <svg
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2.5"
                                  className="w-3 h-3"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M20 6L9 17l-5-5"></path>
                                </svg>
                              </span>
                              {ft}
                            </p>
                          </>
                        );
                      })}
                      <Link
                        href="https://app.myproducthub.com?action=Register"
                        target="_blank"
                        className="mt-auto flex items-center justify-center text-white bg-mph-ocean-blue border-0 py-2 px-4 w-full focus:outline-none hover:bg-mph-orange rounded"
                      >
                        Get Started
                      </Link>
                      {/* <p className="text-sm text-black mt-3">
                        {item.additionalUsers}
                      </p> */}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default PricingTable;
